import React from 'react'
import { ChangeEvent } from 'react'
import Layout from '../../components/Layout'
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet'
import Typography from '@mui/material/Typography';
import { Breadcrumbs, Checkbox, makeStyles } from '@mui/material';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { CallAppPackagesAzureStorage } from '../../utils/AzureStorageApiCall';
import { BlobItem } from '@azure/storage-blob';

export default function Components({ location }: { location: Location }) {

    // lists to store blob items per platform
    const [androidPackages, setAndroidPackages] = React.useState<BlobItem[]>([])
    const [iOSPackages, setiOSPackages] = React.useState<BlobItem[]>([])
    const [windowsPackages, setWindowsPackages] = React.useState<BlobItem[]>([])
    const [versionPackages, setVersionPackages] = React.useState<BlobItem[]>([])
    const [versionTypes, setVersionTypes] = React.useState<(string[])>([])
    const [selectedVersionTypes, setSelectedVersionTypes] = React.useState<(string[])>([])

    // get the type of app ('GO' or 'PRO') from the query parameters (only accept 'GO', all other cases including undefined will end up as 'PRO')
    const appType = location.search.replace('?type=', '').toUpperCase() === 'GO' ? 'Go' : 'Pro';

    // get blob items from the azure storage account, already sorted by date (descending) and per platform
    React.useEffect(() => {
        async function fetchData() {
            const appPackagesBlobData = await CallAppPackagesAzureStorage(appType.toUpperCase())
            setAndroidPackages(appPackagesBlobData.androidBlobItems)
            setiOSPackages(appPackagesBlobData.iOSBlobItems)
            setWindowsPackages(appPackagesBlobData.windowsBlobItems)
            setVersionPackages(appPackagesBlobData.versionBlobItems)
            setVersionTypes(appPackagesBlobData.versionTypes)
            setSelectedVersionTypes(appPackagesBlobData.versionTypes)
        }
        fetchData()
    }, [])

    // method to download a package, will create a url pointing to the specified package on the blob storage
    const downloadPackage = (packageLocation: string) => {
        window.location.href = `https://${process.env.GATSBY_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net/${process.env.GATSBY_AZURE_APPPACKAGES_CONTAINER_NAME}/${packageLocation}`;
    };

    // method to install a package on iOS, will create an iOS specific url pointing to a manifest plist file, which in turn contains the information about the .ipa file
    const installiOSPackage = (manifestLocation: string) => {
        window.location.href = `itms-services://?action=download-manifest&url=https://${process.env.GATSBY_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net/${process.env.GATSBY_AZURE_APPPACKAGES_CONTAINER_NAME}/${manifestLocation}`;
    };

    // eventhandler to handle when a user checks/unchecks a checkbox to filter on specific package types
    const handleChange = (c: ChangeEvent<HTMLInputElement>) => {
        // create a new list and set that as the selectedVersionTypes, based on all versionTypes, only include items that are checked
        setSelectedVersionTypes(versionTypes.reduce((newList, item) => {
            if (item === c.target.id) {
                if (c.target.checked){
                    // the checkbox for this item has been checked during this event, so add it to the list (if it was not checked, it will not be added to the list)
                    newList.push(item);
                }
            }
            else{
                // the checkbox for this item was not checked or unchecked during this event, so we will just add it to the new list to include it in the filtering
                newList.push(item);
            }
            return newList;
        }, []));
    };

    return (
        <Layout location={location}>
            <Helmet title={`SEM Wiki - Install Sem ` + appType} defer={false} />
            <Grid item container direction="row" justifyContent="space-evenly" style={{ paddingTop: 40, width: '100%' }}>
                <Grid item sx={{ marginTop: '40px', marginLeft: '7.69%', marginRight: '15.38%', width: '100%', paddingBottom: "70px" }} component='article'>
                    <Breadcrumbs separator="/">
                        <Link href="/" underline='none' style={{ color: '#008200', fontSize: '14px', fontWeight: 400 }}>
                            Home
                        </Link>
                        <Link href="#" underline='none' style={{ color: '#008200', fontSize: '14px', fontWeight: 400 }}>
                            What is SEM?
                        </Link>
                        <Typography style={{ fontSize: '14px', fontWeight: 400 }}>
                            Install SEM {appType}
                        </Typography>
                    </Breadcrumbs>
                    <Typography sx={{ fontSize: "32px", lineHeight: "36.48px", color: '#333333', fontWeight: '700', marginTop: "58px" }} gutterBottom>
                        Install SEM {appType} - {versionPackages.length} versions
                    </Typography>
                    <Typography style={{ fontWeight: 400, color: "#666666", fontSize: '16px' }}>
                        The latest versions are shown here, newest on top.<br />
                        Install on your device by clicking the corresponding button for your platform.
                    </Typography>
                    {/* if there are multiple package types, add a filtering mechanism allowing the user to select which types to display */}
                    <Typography style={{ fontWeight: 400, color: "#666666", fontSize: '16px', display: versionTypes.length > 1 ? 'block' : 'none' }}>
                        Filter one or more types to display:
                        {versionTypes.slice().map((versionType, index: number) => (
                            <Grid key={index}>
                                <Checkbox id={versionType} onChange={handleChange} checked={selectedVersionTypes.find(selectedVersionType => selectedVersionType === versionType) !== undefined} />{versionType} - {versionPackages.filter(versionPackage => versionPackage.metadata.type === versionType).length} versions
                            </Grid>
                        ))}
                    </Typography>
                    {/* use the versionpackages to select the versions, already sorted by date descending so newest on top. Only show types that are selected, to implement the filtering mechanism from above. */}
                    {versionPackages.filter(versionPackage => selectedVersionTypes.find(selectedVersion => selectedVersion === versionPackage.metadata.type) !== undefined).slice().map((version, index: number) => (
                        <Grid key={index}>
                            {/* start with a divider to divide the different versions, and then output some info about this version */}
                            <Divider sx={{ marginTop: "12px", marginBottom: "12px" }} />
                            <Typography style={{ fontWeight: 700, color: "#333333", fontSize: '18px' }}>
                                Version {version.metadata.versionToDisplay}
                            </Typography>
                            <Typography style={{ fontWeight: 400, color: "#666666", fontSize: '14px' }}>
                                Date: {version.properties.createdOn.toLocaleString()}<br />
                                Type: {appType == "Go" ? "Go" : version.metadata.type}<br />
                            </Typography>
                            <Typography style={{ fontWeight: 400, color: "#666666", fontSize: '14px', display: process.env.GATSBY_AZURE_APPPACKAGES_SHOW_DEVELOP_METADATA.toUpperCase() == 'TRUE' ? 'block' : 'none' }}>
                                Branch: {version.metadata.branch}<br />
                                Commit: <a href={`https://hsem.visualstudio.com/HEINEKEN%20SFA/_git/Heineken%20SEM%20Mobile/commit/${version.metadata.commit}?refName=${version.metadata.branch}`} target='_blank'>{version.metadata.commit}</a><br />
                                Sentry logging: <a href={version.metadata.sentryUrl} target='_blank'>{version.metadata.sentryUrl === undefined ? 'not available for this version' : 'click here'}</a><br />
                            </Typography>
                            <Typography style={{ fontWeight: 400, color: "#666666", fontSize: '14px' }}>
                                {/* If an iOS package exists, add a direct downloadlink to the ipa file.
                                    This cannot be used to install the app on an iOS device, but it can be used to
                                    download the ipa file, for example to store it later on SharePoint or to distribute it from an MDM. */}
                                {iOSPackages.filter(a => a.metadata.version === version.metadata.version).map((iOSPackage) => (
                                    <Typography style={{ fontWeight: 400, color: "#666666", fontSize: '14px' }}>
                                        Direct download link for iOS ipa file: <a href={`https://${process.env.GATSBY_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net/${process.env.GATSBY_AZURE_APPPACKAGES_CONTAINER_NAME}/${iOSPackage.name}`}>{iOSPackage.name}</a>
                                    </Typography>
                                ))}
                                Download or install version {version.metadata.versionToDisplay} on your device:
                            </Typography>
                            {/* All version info is shown, (same for all platforms), now add a button per platform.
                                To accomplish that, we search for the item that matches the current version and then create a button pointing to the blob item.
                                Only if a version for this platform exists, a button will be shown.
                                So if for example Android is still compiling this version, only iOS and Windows buttons will be shown. */}
                            {androidPackages.filter(a => a.metadata.version === version.metadata.version).map((androidPackage) => (
                                <Button variant="contained" sx={{ margin: 2 }} onClick={() => downloadPackage(androidPackage.name)}>
                                    Android
                                </Button>
                            ))}
                            {iOSPackages.filter(a => a.metadata.version === version.metadata.version && a.metadata.plistFile !== undefined).map((iOSPackage) => (
                                <Button variant="contained" sx={{ margin: 2 }} onClick={() => installiOSPackage(iOSPackage.metadata.plistFile)}>
                                    iOS
                                </Button>
                            ))}
                            {windowsPackages.filter(a => a.metadata.version === version.metadata.version).map((windowsPackage) => (
                                <Button variant="contained" sx={{ margin: 2 }} onClick={() => downloadPackage(windowsPackage.name)}>
                                    Windows
                                </Button>
                            ))}
                        </Grid>
                    ))}
                    <Divider sx={{ marginTop: "12px", marginBottom: "12px" }} />
                    <Typography sx={{ fontSize: "32px", lineHeight: "36.48px", color: '#666666', fontWeight: '700', marginTop: "58px", fontStyle: "italic" }} gutterBottom visibility={versionPackages.length === 0 ? 'visible' : 'hidden'}>
                        No versions found for SEM {appType}
                    </Typography>
                </Grid>
            </Grid>
        </Layout>
    )
}